<cr-avid-header></cr-avid-header>

<div class="d-flex justify-content-center">
    <main class="main-content card">
        <!-- IF LOADING -->
        <span *ngIf="loading; else mainContent" class="spinner-background">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </span>

        <!-- ELSE MAIN CONTENT -->
        <ng-template #mainContent>
            <cr-avid-landing-page
                *ngIf="activePage === 'landing'"
                [enrollmentStatus]="enrollmentStatus"
                [employeeKey]="employeeKey ?? ''"
                (gaveBiometricConsent)="updateEnrollmentStatus()"
                (goToRosterPage)="activePage = 'roster'"
            >
            </cr-avid-landing-page>

            <cr-avid-landing-page-generic
                *ngIf="activePage === 'landing-generic'"
            >
            </cr-avid-landing-page-generic>

            <cr-avid-roster-entries
                *ngIf="activePage === 'roster' && enrollmentStatus"
                [entries]="enrollmentStatus.employees"
                (goToLandingPage)="activePage = 'landing'"
            >
            </cr-avid-roster-entries>

            <cr-avid-read-aloud
                *ngIf="activePage === 'read-aloud'"
                [pin]="enrollmentStatus?.pin"
                [phone]="enrollmentStatus?.phoneNumber"
            >
            </cr-avid-read-aloud>
        </ng-template>
    </main>
</div>

<cr-avid-disconnected *ngIf="disconnected"></cr-avid-disconnected>
