<svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clip-path="url(#clip0_1417_9094)">
        <path
            d="M20 40C8.97317 40 0 31.0268 0 20C0 8.97317 8.97317 0 20 0C31.0268 0 40 8.97317 40 20C40 31.0268 31.0268 40 20 40ZM20 2.92683C10.5854 2.92683 2.92683 10.5854 2.92683 20C2.92683 29.4146 10.5854 37.0732 20 37.0732C29.4146 37.0732 37.0732 29.4146 37.0732 20C37.0732 10.5854 29.4146 2.92683 20 2.92683Z"
            fill="#C5ECE8"
        />
        <path
            d="M30.8289 25.5852L29.8435 29.8828C29.7045 30.4901 29.1728 30.9145 28.5509 30.9145C17.846 30.9145 9.13867 22.1633 9.13867 11.4047C9.13867 10.7804 9.56062 10.2438 10.1655 10.1072L14.4436 9.11694C15.0655 8.97303 15.7021 9.29742 15.9606 9.89011L17.9338 14.5169C18.1655 15.0608 18.0094 15.6974 17.5533 16.0706L15.2679 17.9145C16.7094 20.8657 19.0972 23.2657 22.0362 24.7145L23.907 22.4194C24.2753 21.9608 24.9118 21.7999 25.4533 22.0364L30.0582 24.0194C30.6118 24.3121 30.9728 24.9608 30.8289 25.5828V25.5852Z"
            fill="#90D7D1"
        />
    </g>
    <defs>
        <clipPath id="clip0_1417_9094">
            <rect width="40" height="40" fill="white" />
        </clipPath>
    </defs>
</svg>
