import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BackButtonSvgComponent } from './svg-components/back-button-svg/back-button-svg.component';
import { PhoneSvgComponent } from './svg-components/phone-svg/phone-svg.component';
import { PageSvgComponent } from './svg-components/page-svg/page-svg.component';
import { QuietSvgComponent } from './svg-components/quiet-svg/quiet-svg.component';
import { WarningSvgComponent } from './svg-components/warning-svg/warning-svg.component';

@NgModule({
    imports: [BrowserModule, HttpClientModule],
    declarations: [
        BackButtonSvgComponent,
        PhoneSvgComponent,
        PageSvgComponent,
        QuietSvgComponent,
        WarningSvgComponent,
    ],
    exports: [
        BackButtonSvgComponent,
        PhoneSvgComponent,
        PageSvgComponent,
        QuietSvgComponent,
        WarningSvgComponent,
    ],
    providers: [],
    bootstrap: [],
})
export class SharedModule {}
