import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of, tap, retry } from 'rxjs';
import {
    EmployeeEnrollmentStatus,
    ProcessingStatus,
    WebSocketMessage,
} from '../models/models';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class EnrollmentStatusService {
    private _employeeStatus: EmployeeEnrollmentStatus | undefined;
    private _endpoint: string = environment.apiBaseUri + '/api/GetEnrollmentStatus/';

    constructor(private http: HttpClient) {}

    getEnrollmentStatus(
        key: string
    ): Observable<EmployeeEnrollmentStatus | undefined> {
        if (this._employeeStatus) {
            return of(this._employeeStatus);
        } else {
            const url = `${this._endpoint}${key}`;

            return this.http
                .get<EmployeeEnrollmentStatus | undefined>(url)
                .pipe(
                    tap((status) => (this._employeeStatus = status)),
                    map((status) => status),
                    retry(1),
                    catchError(() => of((this._employeeStatus = undefined)))
                );
        }
    }

    createCallStatus(mssg: WebSocketMessage | undefined): ProcessingStatus {
        let status: ProcessingStatus = {
            heading: '',
        };

        if (!mssg) { return status };

        if (mssg.status === 'processing') {
            status = {
                heading: 'Processing...',
                icon: 'icon-loading.gif',
            };
        } else if (mssg.status === 'success') {
            status = {
                heading: mssg.prompt ?? 'Great!',
                progress: mssg.progress,
            };
        } else if (mssg.status === 'error' && mssg.errorType === 'verbatim') {
            status = {
                heading: "Let's try that again.",
                body: 'Please read the prompt exactly as you see it on screen',
                icon: 'icon-error.gif',
            };
        } else if (mssg.status === 'error' && mssg.errorType === 'quality') {
            status = {
                heading: "Sorry, we didn't get that...",
                body: 'Please record in a quiet environment.',
                icon: 'icon-error.gif',
            };
        } else if (mssg.status === 'error' && mssg.errorType === 'tryagain') {
            status = {
                heading: "Let's try that again.",
                body: "The voice enrollment was not successful. Let's try again",
                icon: 'icon-error.gif',
            };
        } else if (mssg.status === 'error' && mssg.errorType === 'generic') {
            status = {
                heading: 'Oops',
                body: "Something went wrong. Let's try that again.",
                icon: 'icon-error.gif',
            };
        } else if (mssg.status === 'error' && mssg.errorType === 'signalr') {
            status = {
                heading: 'Oops',
                body: "Something went wrong. Refresh the page or click the link on your enrollment email to try again.",
                icon: 'icon-error.gif',
            };
        } else if (
            mssg.status === 'done' &&
            mssg.prompt === 'already enrolled'
        ) {
            status = {
                heading: "You're, all set!",
                body: "You've already enrolled in the Agent Voice ID program!",
                icon: 'icon-checkmark.gif',
            };
        } else if (mssg.status === 'done') {
            status = {
                heading: 'Okay, all set!',
                body: "You've been successfully enrolled in Agent Voice ID. Thanks and have a great day!",
                icon: 'icon-checkmark.gif',
            };
        } else if (mssg.status === 'disconnected') {
            status = {
                heading: 'Doh!',
                body: 'We were disconnected. Please call again to continue enrollment.',
                icon: 'icon-not-connected.gif',
                showPhoneNumber: true,
            };
        }

        return status;
    }
}
