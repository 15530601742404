import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'cr-avid-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent {
    @Output() buttonClick = new EventEmitter();

    public buttonClickHandler(): void {
        this.buttonClick.emit();
    }
}
