import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, first, of, retry, tap } from 'rxjs';
import { WebSocketMessage } from '../models/models';
import { HttpClient } from '@angular/common/http';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';

declare global {
    interface Window {
        _ws: any;
        _ws_to: number;
    }
}

window._ws = window._ws || {};
window._ws_to = window._ws_to || 0;

type ConnectionState = 'Connected' | 'Disconnected';

@Injectable({
    providedIn: 'root',
})
export class SignalRService {
    public messages$ = new BehaviorSubject<WebSocketMessage | undefined>(undefined);
    private _disconnectedSubject$: Subject<boolean> = new Subject();
    public disconnected$: Observable<boolean> = this._disconnectedSubject$.asObservable();

    private _connection: HubConnection;
    private progress: number = 0;
    private readonly _newMessageTarget = 'newMessage';
    private _endpoint: string = `${environment.apiBaseUri}/api/negotiate`;

    constructor(private _http: HttpClient) {}

    /**
     * fetches the connction information needed to establish a connection to our signal r hub
     * @param employeeKey the employee enrollment key
     */
    public negotiate(employeeKey: string | null): void {
        if (!employeeKey) {
            return;
        }

        this._http
            .get(this._endpoint)
            .pipe(
                first(), // only take first value then unsubscribe
                tap((connectionInfo) =>
                    this._connect(connectionInfo, employeeKey)
                ),
                retry(1),
                catchError((err) => of(this._onError(err)))
            )
            .subscribe();
    }

    public getConnectionState = (): ConnectionState => {
        return this._connection
            ? <ConnectionState>this._connection.state
            : 'Disconnected';
    };

    /**
     * configure & connect to the signal r hub
     * @param connectionInfo the connection info returned by the 'negotiate' endpoint
     * @param employeeKey the employee enrollment key
     */
    private _connect = (connectionInfo: any, employeeKey: string): void => {
        // configure access token returned by server
        const options = {
            accessTokenFactory: () => connectionInfo.accessToken,
        };

        // configure the connection to the signalR server
        this._connection = new HubConnectionBuilder()
            .withUrl(connectionInfo.url, options)
            .withAutomaticReconnect() // should automatically force a reconnect
            .build();

        // debug purposes
        window._ws = this._connection;

        // attempt to connect
        this._connection
            .start()
            .then(() => {
                this._sendIdentity(employeeKey);
                this._messageListener();
                this._disconnectListener(employeeKey);
                this._disconnectedSubject$.next(false);
            })
            .catch((err) => this._onError(err));
    };

    /**
     * invoke 'WhoAmI' endpoint to tell signalR our employee key
     * this allows signalR to communicate directly with us
     */
    private _sendIdentity = (employeeKey: string): void => {
        this._connection.invoke('WhoAmI', employeeKey);
    };

    /**
     * broadcast the message when we receive a 'newMessage' event from signal r
     * if the user is done, we will clean up the conenction and unsubscribe
     */
    private _messageListener = (): void => {
        this._connection.on(
            this._newMessageTarget,
            (mssg: WebSocketMessage) => {
                this.progress = mssg.progress;
                if (mssg.status === 'done' && this._connection) {
                    this.messages$.next(mssg);
                    this._connection.stop();
                } else if (mssg.status === 'listening' && mssg.progress > 0) {
                    this._showSuccessFeedbackThenEmitMssg(mssg);
                } else {
                    this.messages$.next(mssg);
                }
            }
        );
    };

    /**
     * emits a success event, to give user positive feedback for completing an utterance
     * @param mssg the signalR message we received
     */
    private _showSuccessFeedbackThenEmitMssg(mssg: WebSocketMessage): void {
        const successFeedback: WebSocketMessage = {
            ...mssg,
            status: 'success',
            prompt: this._getSuccessFeedback(mssg),
        };
        this.messages$.next(successFeedback);
        setTimeout(() => this.messages$.next(mssg), 3000);
    }

    /**
     * listens for a disconnect event, if the user is not done
     * will attempt to reconenct; if user is done we ignore the event altogether
     * @param employeeKey the employee enrollment key
     */
    private _disconnectListener = (employeeKey: string) => {
        this._connection.onclose((x) => {
            if (this.progress < 100) {
                this._disconnectedSubject$.next(true);
                console.error('WebSocket connection closed');

                // debug purposes
                if (window._ws_to) {
                    setTimeout(() => {
                        this.negotiate(employeeKey);
                    }, window._ws_to);
                } else {
                    this.negotiate(employeeKey);
                }
            }
        });
    };

    /**
     * on an error to connect to signal r
     * broadcast an error message telling user to refresh page
     * @param err the error receved while attempting to connect to signal r
     */
    private _onError = (err: any): void => {
        console.error(err);
        const signalRErrorMssg: WebSocketMessage = {
            status: 'error',
            errorType: 'signalr',
            prompt: null,
            progress: this.progress,
        };

        this.messages$.next(signalRErrorMssg);
    };

    /**
     * helper function =>
     * determines the success feedback message by progress amount
     * @param mssg the signalR message we received
     * @returns thhe user feedback message
     */
    private _getSuccessFeedback(mssg: WebSocketMessage): string {
        if (mssg.progress < 40) {
            return 'Great!';
        } else if (mssg.progress >= 40 && mssg.progress < 80) {
            return 'Nailed it!';
        } else {
            return 'Almost Done!';
        }
    }
}
