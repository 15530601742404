import { Component } from '@angular/core';
import { WebSocketMessage } from 'src/app/models/models';
import { fadeIn } from 'src/app/animations/animations';

@Component({
    selector: 'cr-avid-already-enrolled',
    templateUrl: './already-enrolled.component.html',
    styleUrls: ['./already-enrolled.component.scss'],
    animations: [fadeIn],
})
export class AlreadyEnrolledComponent {
    // hard code a message to pass to call status component
    message: WebSocketMessage = {
        status: 'done',
        errorType: null,
        prompt: 'already enrolled',
        progress: 100,
    };
}
