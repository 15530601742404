<svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clip-path="url(#clip0_1417_9105)">
        <path
            d="M20 40C8.97317 40 0 31.0268 0 20C0 8.97317 8.97317 0 20 0C31.0268 0 40 8.97073 40 20C40 31.0293 31.0268 40 20 40ZM20 2.92683C10.5854 2.92683 2.92683 10.5854 2.92683 20C2.92683 29.4146 10.5854 37.0732 20 37.0732C29.4146 37.0732 37.0732 29.4146 37.0732 20C37.0732 10.5854 29.4146 2.92683 20 2.92683Z"
            fill="#C5ECE8"
        />
        <path
            d="M23.174 11.4685C23.174 11.0587 22.9399 10.6856 22.5691 10.5075C22.2009 10.3295 21.7618 10.3807 21.4423 10.6368L16.4033 14.6685H11.4448C10.857 14.6685 10.3789 15.1465 10.3789 15.7344V24.2661C10.3789 24.8539 10.857 25.3319 11.4448 25.3319H16.4033L21.4423 29.3636C21.7618 29.6197 22.2009 29.6685 22.5691 29.4929C22.9374 29.3148 23.174 28.9417 23.174 28.5319V11.4685Z"
            fill="#90D7D1"
        />
        <path
            d="M29.6257 20.0002C29.6257 22.6514 28.5525 25.049 26.8159 26.7856L25.3086 25.2783C26.6598 23.927 27.494 22.0612 27.494 20.0002C27.494 17.9392 26.6598 16.0734 25.3086 14.7222L26.8159 13.2148C28.5525 14.9514 29.6257 17.3514 29.6257 20.0002Z"
            fill="#90D7D1"
        />
    </g>
    <defs>
        <clipPath id="clip0_1417_9105">
            <rect width="40" height="40" fill="white" />
        </clipPath>
    </defs>
</svg>
