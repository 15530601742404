<div class="unrecognized">
    <div class="row justify-content-center">
        <div class="col-lg-9 col-md-12">
            <h1>Thanks for your interest in Agent Voice ID!</h1>
        </div>
        <p>
            Hmm, we weren't able to identify you.
            <br />
            <span class="color-primary-green fw-bold">
                Please make sure that you're using the link that was emailed to
                you.
            </span>
        </p>
        <p class="mt-3">
            <span class="fw-bold">What is Agent Voice ID?</span>
            <br />
            This Feature will allow us to recognize your voice and give you
            credit for all your hard work!
        </p>
    </div>
</div>
