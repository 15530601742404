<svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1042 1.76222C9.60483 0.912592 8.39864 0.912592 7.89928 1.76222L0.185204 14.8872C-0.328958 15.762 0.288313 16.875 1.28766 16.875H16.7158C17.7152 16.875 18.3324 15.762 17.8183 14.8872L10.1042 1.76222ZM9.00082 5.25C8.53676 5.25 8.15707 5.62969 8.15707 6.09375V10.5938C8.15707 11.0613 8.535 11.4375 9.00082 11.4375C9.46664 11.4375 9.84457 11.0596 9.84457 10.5938V6.09375C9.84457 5.62793 9.46488 5.25 9.00082 5.25ZM7.89551 13.7072C7.89551 14.3175 8.39051 14.8125 9.00082 14.8125C9.61254 14.8125 10.1047 14.3168 10.1061 13.7072C10.1061 13.0969 9.61148 12.6019 9.00082 12.6019C8.39016 12.6019 7.89551 13.0969 7.89551 13.7072Z"
        fill="#EF5350"
    />
</svg>
