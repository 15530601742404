<header class="header p-3 text-white">
    <nav class="container">
        <div class="d-flex flex-wrap align-items-center justify-content-center">
            <!-- LOGO -->
            <a href="/"
                class="text-decoration-none">
                <img src="/assets/images/cr-logo.png"
                    alt="CallRevu Logo">
            </a>
            <div class="header__text text-white">Agent Voice ID Setup</div>
        </div>
    </nav>
</header>