import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of, retry } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class BiometricConsentService {
    private _endpoint: string =
        environment.apiBaseUri + '/api/SetBiometricConsent';

    constructor(private http: HttpClient) {}

    saveBiometricConsent(
        employeeKey: string,
        consentName: string
    ): Observable<boolean> {
        if (!employeeKey || !consentName) {
            return of(false);
        }

        const encodedName = encodeURIComponent(consentName); // remove any spaces or special characters
        const url = `${this._endpoint}/${employeeKey}/${encodedName}`;

        return this.http.post<boolean>(url, null).pipe(
            map(() => true),
            retry(1),
            catchError(() => of(false))
        );
    }
}
