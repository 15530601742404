import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class QueryParamService {

    constructor() {}

    /**
     * Tells us if we should show the roster page
     * @returns boolean flag indicating if roster list query param was present
     */
    public showRosterList(): boolean {
        const parsedUrl = new URL(window.location.href);
        const rosterListParam = parsedUrl.searchParams.get('list');
        return rosterListParam === '1' ? true : false;
    }

    /**
     * grabs the employee key from the url
     * @returns the emplyee key
     */
    public getEmployeeKey(): string | null {
        const parsedUrl = new URL(window.location.href);
        const employeeKey = parsedUrl.searchParams.get('e');
        return employeeKey;
    }
}
