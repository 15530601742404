<div class="welcome">
    <h1>Welcome!</h1>
    <p>
        Let&#39;s get you setup with Agent Voice ID.
        <br />
        This will allow us to recognize your voice and give you credit for all
        your hard work!
    </p>
    <p>
        This simple process will
        <span class="fw-bold"> only take about 5 minutes. </span>
    </p>
    <br />
    <button
        type="button"
        class="btn btn-primary background-primary-blue fs-14px"
        (click)="buttonClickHandler()"
    >
        Get Started
    </button>
</div>
