<svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <rect
        width="28"
        height="28"
        rx="14"
        transform="matrix(-1 0 0 1 28 0)"
    />
    <g clip-path="url(#clip0_1417_9050)">
        <path
            d="M10.5898 13.9999C10.5898 14.1565 10.6509 14.3133 10.7729 14.4327L14.5229 18.1063C14.7671 18.3454 15.1626 18.3454 15.4067 18.1063C15.6509 17.8671 15.6509 17.4797 15.4067 17.2405L12.0977 13.9999L15.4063 10.7587C15.6504 10.5195 15.6504 10.1321 15.4063 9.8929C15.1621 9.65373 14.7666 9.65373 14.5225 9.8929L10.7725 13.5665C10.6504 13.6861 10.5898 13.843 10.5898 13.9999Z"
            fill="#3173E6"
        />
    </g>
    <defs>
        <clipPath id="clip0_1417_9050">
            <rect
                width="8.57174"
                height="10"
                fill="white"
                transform="matrix(0 -1 -1 0 18.7148 18.2856)"
            />
        </clipPath>
    </defs>
</svg>
