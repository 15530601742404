<div class="landing-page__footer card-footer text-muted">
    <!-- IF EMPLOYEE && ROSTER ENTRIES ELSE GENERIC TEXT -->
    <div
        *ngIf="employee && rosterEntries; else genericText"
        class="landing-page__footer-content"
    >
        <!-- EMPLOYEE INFO -->
        <div class="d-flex">
            <div>
                <img
                    class="footer-icon"
                    src="/assets/images/icon-agent.png"
                    alt=""
                />
            </div>
            <div class="employee-info">
                <div class="footer-agent-info-label">
                    ENROLLMENT REQUEST FOR:
                </div>
                <address [@fadeIn]>
                    {{ employee.firstName }} {{ employee.lastName }}
                    <br />
                    {{ employee.email }}
                    <br />

                    <ng-container *ngIf="rosterEntries.length === 1; else multipleAccounts">
                        {{ employee.accountName }}
                    </ng-container>

                    <ng-template #multipleAccounts>
                        <span class="fs-12px">
                            {{ rosterEntries.length }} matching roster entries.
                        </span>
                        <a href="#" (click)="reviewRosterEntries($event)">
                            See All
                        </a>
                    </ng-template>
                </address>
            </div>
        </div>

        <!-- SUPPORT MESSAGE -->
        <div class="d-flex">
            <div>
                <img
                    class="footer-icon"
                    src="/assets/images/icon-question.png"
                    alt=""
                />
            </div>
            <div class="max-w-310px">
                <p>
                    <span class="color-black fw-bold">Something wrong? </span>If
                    this isn&#39;t your account, or something doesn&#39;t seem
                    right—please contact your dealership admin or
                    <a
                        href="https://www.callrevu.com/contact-support"
                        target="_blank"
                        rel="noopener"
                    >
                        CallRevu Support
                    </a>
                </p>
            </div>
        </div>
    </div>

    <!-- GENERIC TEXT -->
    <ng-template #genericText>
        <div class="landing-page__footer-content--generic">
            <img
                class="footer-icon"
                src="/assets/images/icon-question.png"
                alt=""
            />
            <p>
                <span class="color-black fw-bold">
                    Didn't receive an email?
                </span>
                If you didn&#39;t receive an invitation email, please contact
                your dealership admin.
            </p>
        </div>
    </ng-template>
</div>
