import {
    Component,
    EventEmitter,
    Input,
    Output,
    ElementRef,
    Renderer2,
    ViewChild,
    AfterViewInit,
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { FormSubmissionStatus } from 'src/app/models/models';
import { BiometricConsentService } from 'src/app/services/biometric-consent.service';

@Component({
    selector: 'cr-biometric-consent',
    templateUrl: './biometric-consent.component.html',
    styleUrls: ['./biometric-consent.component.scss'],
})
export class BiometricConsentComponent implements AfterViewInit {
    public form: FormGroup;
    public formStatus: FormSubmissionStatus = {
        saving: false,
        success: false,
        error: false,
    };

    @Input() employeeKey: string;

    @Output() gaveBiometricConsent = new EventEmitter();
    @ViewChild('scrollableSection') scrollableSectionRef: ElementRef;

    constructor(
        private renderer: Renderer2,
        private fb: FormBuilder,
        private biometricConsentService: BiometricConsentService
    ) {
        this.form = this.fb.group({
            name: ['', [Validators.required, Validators.pattern('[^0-9]+')]], // only non-numeric characters allowed
            agreement: [false, Validators.requiredTrue],
        });
    }

    ngAfterViewInit(): void {
        this.addScrollShadow();

        this.renderer.listen(
            this.scrollableSectionRef.nativeElement,
            'scroll',
            () => this.addScrollShadow()
        );
    }

    public onFormSubmit(): void {
        const consentName: string = this.form.value.name;
        this.formStatus.saving = true;

        this.biometricConsentService
            .saveBiometricConsent(this.employeeKey, consentName)
            .subscribe((res) => {
                if (res === true) {
                    this.formStatus.saving = false;
                    this.formStatus.success = true; // show success feedback

                    setTimeout(() => {
                        this.formStatus.success = false; // remove success feedback after 2 seconds
                        this.gaveBiometricConsent.emit();
                    }, 2000);
                } else {
                    this.formStatus.saving = false;
                    this.formStatus.error = true; // show error feedback
                }
            });
    }

    private addScrollShadow(): void {
        const element: HTMLElement = this.scrollableSectionRef.nativeElement;
        const topShadow = 'inset 0 10px 10px -10px #adb5bd';
        const bottomShadow = 'inset 0 -10px 10px -10px #adb5bd';
        let shadow: string = 'none';

        if (this.isScrolledToTop(element)) {
            shadow = bottomShadow;
        } else if (this.isScrolledToBottom(element)) {
            shadow = topShadow;
        } else {
            shadow = topShadow + ', ' + bottomShadow;
        }
        this.renderer.setStyle(element, 'boxShadow', shadow);
    }

    private isScrolledToTop(elem: HTMLElement) {
        return elem.scrollTop === 0;
    }

    private isScrolledToBottom(elem: HTMLElement) {
        return elem.scrollHeight - Math.ceil(elem.scrollTop) === elem.clientHeight;
    }
}
