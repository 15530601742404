<div class="welcome">
    <h1>Let&#39;s get setup</h1>
    <p>
        Please ensure that you meet the following criteria before starting
        enrollment:
    </p>

    <div class="row justify-content-center mt-4">
        <div class="col-md-3 col-sm-12">
            <p>
                <cr-avid-phone-svg></cr-avid-phone-svg>
            </p>
            <p class="fs-14px">
                <span class="fw-bold">Prepare your phone</span>
                <br />
                <span class="color-grey">
                    Use the phone you most often use to communicate with
                    customers.
                </span>
            </p>
        </div>
        <div class="col-md-3 col-sm-12">
            <p>
                <cr-avid-page-svg></cr-avid-page-svg>
            </p>
            <p class="fs-14px">
                <span class="fw-bold">Have this page ready</span>
                <br />
                <span class="color-grey">
                    You&#39;ll be asked to read a few prompts displayed on
                    screen.
                </span>
            </p>
        </div>
        <div class="col-md-3 col-sm-12">
            <p>
                <cr-avid-quiet-svg></cr-avid-quiet-svg>
            </p>
            <p class="fs-14px">
                <span class="fw-bold">Quiet environment</span>
                <br />
                <span class="color-grey">
                    A quiet environment will ensure we can capture a quality
                    sample of your voice.
                </span>
            </p>
        </div>
    </div>

    <br />
    <button
        type="button"
        class="btn btn-primary background-primary-blue fs-14px"
        (click)="buttonClickHandler()"
    >
        Let&#39;s Go!
    </button>
</div>
