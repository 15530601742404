
<div class="landing-page" [@fadeIn]>

    <!-- BODY -->
    <div class="landing-page__body card-body">
        <div class="welcome-generic">
            <h1>Thanks for your interest <br class="d-none d-sm-block">in Agent Voice ID!</h1>
            <div class="welcome-generic__body">
                <div class="welcome-generic__body__column">
                    <p class="fw-bold">What is Agent Voice ID?</p>
                    <p>This feature will allow us to recognize your voice and give you credit for all
                        your hard work!
                    </p>
                </div>
                <div class="welcome-generic__body__column">
                    <p class="fw-bold">How do I get started?</p>
                    <p>You should have received an email inviting you to enroll. Click the link within the
                        email to start your enrollment.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- FOOTER -->
    <cr-avid-landing-page-footer></cr-avid-landing-page-footer>
</div>


