import { Component, Input } from '@angular/core';

@Component({
    selector: 'cr-avid-call-us',
    templateUrl: './call-us.component.html',
    styleUrls: ['./call-us.component.scss'],
})
export class CallUsComponent {
    @Input() pin: string | undefined;
    @Input() phone: string | undefined;
}
