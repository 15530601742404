import { ChangeDetectorRef, Component, HostListener, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { EmployeeEnrollmentStatus, WebSocketMessage } from './models/models';
import { EnrollmentStatusService } from './services/enrollment-status.service';
import { SignalRService } from './services/signalr.service';
import { QueryParamService } from './services/query-param.service';

type Page =
    | 'landing'
    | 'landing-generic'
    | 'roster'
    | 'already-enrolled'
    | 'read-aloud';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
    activePage: Page;
    enrollmentStatus: EmployeeEnrollmentStatus | undefined;
    employeeKey: string | null;
    loading: boolean = false;
    disconnected: boolean = false;

    private _subscription$: Subscription = new Subscription();

    constructor(
        private enrollmentStatusService: EnrollmentStatusService,
        private signalRService: SignalRService,
        private queryParams: QueryParamService,
        private ref: ChangeDetectorRef
    ) {}

    /** attempt to reconnect if web page loses focus and re-gains it*/
    @HostListener('document:visibilitychange', ['$event'])
    appVisibility() {
        if (!document.hidden) {
            if (this.signalRService.getConnectionState() === 'Disconnected') {
                this.signalRService.negotiate(
                    this.queryParams.getEmployeeKey()
                );
            }
        }
    }

    //#region lifecycle hooks
    ngOnInit(): void {
        this.loading = true;
        this.employeeKey = this.queryParams.getEmployeeKey();
        this.initApp();
    }

    ngOnDestroy(): void {
        this._subscription$.unsubscribe();
    }
    //#endregion

    public updateEnrollmentStatus(): void {
        if (this.enrollmentStatus) {
            this.enrollmentStatus.biometricConsent = true;
        }
    }

    private initApp(): void {
        if (this.employeeKey) {
            this.activePage = 'landing';
            this.getEnrollmentStatus();
            this.signalRService.negotiate(this.employeeKey); // attempt to connect to signal r
            this._listenForWebSocketDisconnect();
            this._listenForWebSocketMessages();
        } else {
            this.activePage = 'landing-generic';
            this.loading = false;
        }
    }

    private getEnrollmentStatus(): void {
        this.enrollmentStatusService
            .getEnrollmentStatus(this.employeeKey as string)
            .pipe(first()) // unsubscribe after first value emitted
            .subscribe((status) => {
                this.enrollmentStatus = status;
                if (this.enrollmentStatus) {
                    if (this.queryParams.showRosterList()) {
                        this.activePage = 'roster';
                    }
                }
                this.loading = false;
            });
    }

    //#region socket listeners
    /**
     * controls the switch between the home page to the 'read-aloud' page
     * cleans up subscriptions when user completes enrollment
     */
    private _listenForWebSocketMessages(): void {
        this._subscription$.add(
            this.signalRService.messages$.subscribe(
                (mssg: WebSocketMessage | undefined) => {
                    // only display read-aloud page if mssg has a value
                    if (mssg && this.activePage !== 'read-aloud') {
                        this.activePage = 'read-aloud';
                    }

                    // stop listening so the 'disconnected' screen is not displayed
                    if (mssg?.status === 'done') {
                        this._subscription$.unsubscribe();
                    }

                    // if long-polling on signlaR, message come in-between lifecycle hooks
                    // this is needed to refresh the ui so it instantly show/hides the read-along page
                    this.ref.detectChanges();
                }
            )
        );
    }

    /**
     * displays the web socket disconnected error message
     */
    private _listenForWebSocketDisconnect(): void {
        this._subscription$.add(
            this.signalRService.disconnected$.subscribe((disconnected) => {
                this.disconnected = disconnected;
                this.ref.detectChanges();
            })
        );
    }
    //#endregion
}
