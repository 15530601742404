<div class="landing-page" [@fadeIn]>
    <!-- back button -->
    <div
        *ngIf="['biometricConsent', 'getStarted', 'giveUsACall'].includes(activeStep)"
        (click)="goBack()"
        class="landing-page__go-back"
    >
        <cr-avid-back-button-svg></cr-avid-back-button-svg>
    </div>

    <!-- BODY -->
    <div class="landing-page__body card-body">
        <ng-container [ngSwitch]="activeStep">

            <!-- WELCOME -->
            <ng-container *ngSwitchCase="'welcome'">
                <cr-avid-welcome
                    [@fadeIn]
                    (buttonClick)="gotoStep(enrollmentStatus?.biometricConsent === true ? 'getStarted' : 'biometricConsent')"
                ></cr-avid-welcome>
            </ng-container>

            <!-- BIOMETRIC CONSENT -->
            <ng-container *ngSwitchCase="'biometricConsent'">
                <cr-biometric-consent
                    [@fadeIn]
                    [employeeKey]="employeeKey"
                    (gaveBiometricConsent)="onGaveBiometricConsent()"
                ></cr-biometric-consent>
            </ng-container>

            <!-- GET STARTED -->
            <ng-container *ngSwitchCase="'getStarted'">
                <cr-avid-get-started
                    [@fadeIn]
                    (buttonClick)="gotoStep('giveUsACall')"
                ></cr-avid-get-started>
            </ng-container>

            <!-- GIVE US A CALL -->
            <ng-container *ngSwitchCase="'giveUsACall'">
                <cr-avid-call-us
                    [@fadeIn]
                    [pin]="enrollmentStatus?.pin"
                    [phone]="enrollmentStatus?.phoneNumber"
                ></cr-avid-call-us>
            </ng-container>

            <!-- ALREADY ENROLLED -->
            <ng-container *ngSwitchCase="'enrolled'">
                <cr-avid-already-enrolled [@fadeIn]></cr-avid-already-enrolled>
            </ng-container>

            <!-- UNRECOGNIZED -->
            <ng-container *ngSwitchDefault>
                <cr-avid-unrecognized-enrollment-key
                    [@fadeIn]
                ></cr-avid-unrecognized-enrollment-key>
            </ng-container>
        </ng-container>
    </div>

    <!-- FOOTER -->
    <cr-avid-landing-page-footer
        *ngIf="['welcome', 'unrecognized', 'enrolled'].includes(activeStep)"
        [employee]="employee"
        [rosterEntries]="rosterEntries"
        (goToRosterPage)="reviewRosterEntries($event)"
    ></cr-avid-landing-page-footer>
</div>
