<svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clip-path="url(#clip0_1417_9100)">
        <path
            d="M20 40C8.97317 40 0 31.0268 0 20C0 8.97317 8.97317 0 20 0C31.0268 0 40 8.97073 40 20C40 31.0293 31.0268 40 20 40ZM20 2.92683C10.5854 2.92683 2.92683 10.5854 2.92683 20C2.92683 29.4146 10.5854 37.0732 20 37.0732C29.4146 37.0732 37.0732 29.4146 37.0732 20C37.0732 10.5854 29.4146 2.92683 20 2.92683Z"
            fill="#C5ECE8"
        />
        <path
            d="M28.7179 9.83154H11.2838C9.67891 9.83154 8.37891 11.1315 8.37891 12.7364V27.2633C8.37891 28.8681 9.67891 30.1681 11.2838 30.1681H28.7179C30.3228 30.1681 31.6228 28.8681 31.6228 27.2633V12.7364C31.6228 11.1315 30.3228 9.83154 28.7179 9.83154ZM16.3692 12.7364C16.7692 12.7364 17.096 13.0608 17.096 13.4632C17.096 13.8657 16.7716 14.1901 16.3692 14.1901C15.9667 14.1901 15.6423 13.8657 15.6423 13.4632C15.6423 13.0608 15.9667 12.7364 16.3692 12.7364ZM14.1911 12.7364C14.5911 12.7364 14.9179 13.0608 14.9179 13.4632C14.9179 13.8657 14.5935 14.1901 14.1911 14.1901C13.7887 14.1901 13.4643 13.8657 13.4643 13.4632C13.4643 13.0608 13.7887 12.7364 14.1911 12.7364ZM12.0131 12.7364C12.4131 12.7364 12.7399 13.0608 12.7399 13.4632C12.7399 13.8657 12.4155 14.1901 12.0131 14.1901C11.6106 14.1901 11.2862 13.8657 11.2862 13.4632C11.2862 13.0608 11.6106 12.7364 12.0131 12.7364ZM30.1716 27.2633C30.1716 28.0657 29.5204 28.7169 28.7179 28.7169H11.2838C10.4813 28.7169 9.83013 28.0657 9.83013 27.2633V17.095H30.1692V27.2633H30.1716Z"
            fill="#90D7D1"
        />
    </g>
    <defs>
        <clipPath id="clip0_1417_9100">
            <rect width="40" height="40" fill="white" />
        </clipPath>
    </defs>
</svg>
