<div class="biometric-consent">
    <!-- TITLE -->
    <!-- line break on medium only to make it wrap nicer -->
    <h1>Your privacy is <br class="d-none d-md-block d-lg-none">important to us</h1>

    <!-- SUBTITLE -->
    <p>Please review the biometrics information policy below.</p>

    <!-- SCROLLABLE POLICY SECTION -->
    <div #scrollableSection class="scrollable-section">
        <p class="text-center fw-bold">
            Notice Regarding Collection and Use of Biometric Information
        </p>
        <p>
            This notice ("Notice") is provided by CallRevu, LLC (collectively,
            "CallRevu", "Service", "we", "our", "us") to you (collectively,
            "you" and "your") to inform you of our use and collection practices
            relating to you biometric information.
        </p>
        <p>
            We use your biometric voiceprint to identify you as the speaker on
            calls that are monitored by the Service, for the purpose of
            providing superior call analytics to our customers. We will use such
            biometric information, in conjunction with other third parties, when
            you provide verbal input on calls monitored by the Service. We will
            only keep this information for three years after your last
            interaction with the Service. Upon requests or upon notification of
            termination of your employment from your employer, we will
            permanently destroy your biometric information.
        </p>
    </div>

    <!-- CONSENT FORM -->
    <div class="form-wrapper">
        <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
            <div class="row align-items-end text-start">

                <!-- NAME -->
                <div class="col-12 col-md-6 mb-3 mb-md-0">
                    <label for="nameInput" class="form-label">Enter your full legal name</label>
                    <input type="text" class="form-control" id="nameInput" formControlName="name"
                        [ngClass]="{'is-invalid': form.get('name')?.hasError('pattern')}">
                </div>

                <!-- CHECKBOX -->
                <div class="col-4 col-md-2">
                    <div class="form-check mb-md-2">
                        <input type="checkbox" class="form-check-input" id="agreementCheck" formControlName="agreement">
                        <label class="form-check-label margin-top-1px" for="agreementCheck">I agree</label>
                    </div>
                </div>

                <!-- SUBMIT BUTTON -->
                <div class="col-8 col-md-4 text-end">
                    <button type="submit" class="btn btn-primary background-primary-blue" [disabled]="!form.valid">
                        <!-- STANDARD BUTTON TEXT -->
                        <span *ngIf="!formStatus.saving && !formStatus.success">
                            Agree and Continue
                        </span>
                        <!-- IF SAVING -->
                        <span *ngIf="formStatus.saving" class="spinner-border spinner-border-sm text-light" role="status"
                            aria-hidden="true">
                            <span class="visually-hidden">Loading...</span>
                        </span>
                        <!-- ON SAVE SUCCESS -->
                        <span *ngIf="!formStatus.saving && formStatus.success">
                            Consent saved!
                        </span>
                    </button>
                </div>
            </div>
        </form>

        <!-- FEEDBACK MESSAGES -->
        <div *ngIf="form.get('name')?.hasError('pattern')" class="mssg mssg-error">
            Only non-numeric characters are allowed.
        </div>

        <div *ngIf="formStatus.error" class="mssg mssg-error">
            We failed to save your consent. Please try again later or notify your dealership admin.
        </div>
    </div>

    <!-- FOOTER MESSAGE -->
    <div class="footer-message">
        If you do not agree with this policy, please close this window and contact your dealership admin with any
        questions.
    </div>

</div>
