import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { EmployeeEnrollmentStatus, Employee } from 'src/app/models/models';
import { fadeIn } from 'src/app/animations/animations';
import { ActiveStep } from 'src/app/shared/enums/active_step.enum';

@Component({
    selector: 'cr-avid-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss'],
    animations: [fadeIn],
})
export class LandingPageComponent implements OnInit {
    employee: Employee;
    rosterEntries: Employee[];
    activeStep: ActiveStep;

    @Input() employeeKey: string;

    @Input()
    get enrollmentStatus(): EmployeeEnrollmentStatus | undefined {
        return this._enrollmentStatus;
    }
    set enrollmentStatus(
        enrollmentStatus: EmployeeEnrollmentStatus | undefined
    ) {
        if (enrollmentStatus) {
            this._enrollmentStatus = enrollmentStatus;
            this.employee = enrollmentStatus.employees[0];
            this.rosterEntries = enrollmentStatus.employees;
        }
    }
    private _enrollmentStatus: EmployeeEnrollmentStatus;

    @Output() goToRosterPage = new EventEmitter();
    @Output() gaveBiometricConsent = new EventEmitter();

    ngOnInit(): void {
        this.activeStep = this._enrollmentStatus
            ? this._enrollmentStatus.statusDisplayName === 'Complete'
                ? 'enrolled'
                : 'welcome'
            : 'unrecognized';
    }

    reviewRosterEntries(e: MouseEvent): void {
        e.preventDefault();
        this.goToRosterPage.emit();
    }

    goBack(): void {
        const steps: Array<ActiveStep> = [
            'welcome',
            'getStarted',
            'giveUsACall',
        ];
        const index = steps.findIndex((x) => x === this.activeStep);
        this.activeStep = index - 1 >= 0 ? steps[index - 1] : 'welcome';
    }

    gotoStep(activeStep: ActiveStep): void {
        this.activeStep = activeStep;
    }

    onGaveBiometricConsent(): void {
        this.gaveBiometricConsent.emit(); // so we update the enrollment status object
        this.activeStep = 'getStarted';
    }
}
